import { takeEvery, call, put } from "redux-saga/effects";
import { FormActionTypes } from "./actiontype";
import { baseAPI, fetchData } from '../../../services/api';
import {  toast } from "react-toastify";
import {createFormDetailSuccess,createFormDetailFailure} from "./action";
function* workerCreateFormDetail(action: any) {
    const request = action.data;
    try {
        const response = yield call(baseAPI.post, `workflowsetup/app/createforms?appId=${request.appId}`, request.data);
      if (response) {
        console.log('Form...')
        yield put(createFormDetailSuccess(response))
      } else {
        toast('Internal Server Error');
        yield put(createFormDetailFailure(response))
      }
    } catch (error) {
        toast('Internal Server Error');
      yield put(createFormDetailFailure(error))
    }
  }

  function* FormModuleSaga() {
    yield takeEvery(FormActionTypes.CREATE_FORM_REQUEST, workerCreateFormDetail);   
  }
  
  export default FormModuleSaga;