import { INCREMENT_MODULE1 } from '../actions/module1Actions';

const initialState = {
  module1Counter: 0,
};

const module1Reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case INCREMENT_MODULE1:
      return { ...state, module1Counter: state.module1Counter + 1 };
    default:
      return state;
  }
};

export default module1Reducer;