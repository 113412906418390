import React from "react";
import { useNavigate } from "react-router-dom";
import { Table, Modal, ModalBody, Button, ModalHeader, Col, Form, Input, Label, Row } from "reactstrap";
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
});

const CreateApp = (props: any) => {
  const navigate = useNavigate();

  const initialValues = {
    name: '',
    description: '',
  };



  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        centered
        size="lg"
        className="border-0"
        modalClassName='modal fade zoomIn'
      >

        <ModalHeader className="p-3 bg-info-subtle mb-2 text-center">
          Create Application
        </ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={props.handleSubmit}
        >
          <FormikForm className="tablelist-form">
            <ModalBody className="modal-body">
              <Row className="g-3">
                <Col lg={12}>
                  <Label htmlFor="name-field" className="form-label">
                    Name
                  </Label>
                  <Field
                    name="name"
                    id="name-field"
                    className="form-control"
                    placeholder="Enter Name"
                    type="text"
                  />
                  <ErrorMessage name="name" component="div" className="error" />
                </Col>
                <Col lg={12}>
                  <Label htmlFor="description-field" className="form-label">
                    Description
                  </Label>
                  <Field
                    name="description"
                    id="description-field"
                    as="textarea"
                    className="form-control"
                    placeholder="Enter description"
                    rows={4}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error"
                  />
                </Col>
              </Row>
            </ModalBody>
            <div className="modal-footer">
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  onClick={() => {
                    props.setModal(false);
                  }}
                  className="btn-light"
                >
                  Close
                </Button>
                <button type="submit" className="btn btn-success" id="add-btn">
                  Create
                </button>
              </div>
            </div>
          </FormikForm>
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default CreateApp;
