// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { useMsal,useIsAuthenticated } from '@azure/msal-react';
import BpmnModelerMain from './Bpmn/BPMNmain';
import FormJS from './Form/FormJS';
import Formviewer from './Viewer/Formviewer';
import BpmnViewer from './Viewer/BpmnViewer';
import { Provider } from 'react-redux';
import DashboardComponent from '../src/Dashboard/index';
import Layout from './Dashboard/layout';
import WorkFlowList from './WorkFlowGrid/manageGrid';
import Apps from './App/app';
import { ToastContainer } from 'react-toastify';



const App = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const loginRequest = {
    scopes: ['api://d6dc2c70-31ed-45ea-84ad-c95226375a29/access_as_user'],
  };

  const handleLogin = async () => {
    try {
      console.log('instance...', instance)
      instance.loginRedirect(loginRequest).catch((e) => {
        // Handle login error
        console.error('Login failed', e);
      });
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  const handleLogout = () => {
    instance.logout();
  };

  // // Automatically initiate login on component mount
  // useEffect(() => {
  //   if (accounts.length === 0) {
  //     handleLogin();
  //   }
  // }, [accounts]);
  return (
    // <div>
    //   {accounts.length === 0 ? (
    //     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
    //       <div style={{
    //         background: '#d0e2d8',
    //         textAlign: 'center',
    //         padding: '20px',
    //         borderRadius: '10px',
    //         width: '80%', // Set the initial width
    //         maxWidth: '400px', // Limit the maximum width
    //         height: '30%'
    //       }}>
    //         <div className="card-body" style={{ marginTop: 20, padding: '10px' }}>
    //           <h2 className="card-title">Low Code</h2>
    //           <p className="card-text">Click Sign In with Azure Accounts...</p>
    //           <a onClick={handleLogin} className="btn btn-primary">Sign In</a>
    //         </div>
    //       </div>
    //     </div>


    //   ) : (
       
    //       <BrowserRouter>
    //       <Layout >
    //         <Routes>
    //         <Route path='/' element={<DashboardComponent />} />
    //           <Route path='/bpmn' element={<BpmnModelerMain />} /> 
    //            <Route path='/form' element={<FormJS />} />
    //           <Route path='/formViewer' element={<Formviewer />} />
    //           <Route path='/BpmnViewer' element={<BpmnViewer />} /> 
    //         </Routes>
    //         </Layout>
    //       </BrowserRouter>
        
    //   )}
    // </div>
    <BrowserRouter>
    <Layout >
      <Routes>
      <Route path='/' element={<DashboardComponent />} />
        <Route path='/bpmn' element={<BpmnModelerMain />} /> 
        <Route path='/workflowgrid' element={<WorkFlowList />} />
        <Route path='/apps' element={<Apps />} />
         <Route path='/form' element={<FormJS />} />
        <Route path='/formViewer' element={<Formviewer />} />
        <Route path='/BpmnViewer' element={<BpmnViewer />} />     
                
      </Routes>
      <ToastContainer position="bottom-right" /> 
      </Layout>
    </BrowserRouter>  
  )
};

export default App;
