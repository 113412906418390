import { CREATE_OPTIONS } from '../../util/CreateOptionsUtil';

interface Group {
  id: string;
  name: string;
}

interface Target {
  type: string;
}

interface CreateOption {
  actionName: string;
  className: string;
  label: string;
  target: Target;
  description?: string;
  group: Group;
  search?: string;
  rank?: number;
}

/**
 * This module is a create menu provider for the popup menu.
 */
export default function CreateMenuProvider(
  elementFactory: any,
  popupMenu: any,
  create: any,
  autoPlace: any,
  mouse: any,
  translate: any
) {
  this._elementFactory = elementFactory;
  this._popupMenu = popupMenu;
  this._create = create;
  this._autoPlace = autoPlace;
  this._mouse = mouse;
  this._translate = translate;

  this.register();
}

CreateMenuProvider.$inject = [
  'elementFactory',
  'popupMenu',
  'create',
  'autoPlace',
  'mouse',
  'translate'
];

/**
 * Register create menu provider in the popup menu
 */
CreateMenuProvider.prototype.register = function () {
  this._popupMenu.registerProvider('bpmn-create', this);
};

/**
 * Returns the create options as menu entries
 *
 * @param {djs.model.Base} element
 *
 * @return {Array<Object>} a list of menu entry items
 */
CreateMenuProvider.prototype.getPopupMenuEntries = function () {
  const entries: { [key: string]: any } = {};

  // map options to menu entries
  CREATE_OPTIONS.forEach((option: CreateOption) => {
    const {
      actionName,
      className,
      label,
      target,
      description,
      group,
      search,
      rank
    } = option;

    const targetAction = this._createEntryAction(target);

    entries[`create-${actionName}`] = {
      label: label && this._translate(label),
      className,
      description: description && this._translate(description),
      group: group && {
        ...group,
        name: this._translate(group.name)
      },
      search: search || '', // handle the case where search is undefined
      rank: rank || 0, // handle the case where rank is undefined
      action: {
        click: targetAction,
        dragstart: targetAction
      }
    };
  });

  return entries;
};

/**
 * Create an action for a given target
 *
 * @param {Object} target
 * @returns {Object}
 */
CreateMenuProvider.prototype._createEntryAction = function (target: Target) {
  const create = this._create;
  const mouse = this._mouse;
  const popupMenu = this._popupMenu;
  const elementFactory = this._elementFactory;

  let newElement;

  return (event: any) => {
    popupMenu.close();

    // create the new element
    if (target.type === 'bpmn:Participant') {
      newElement = elementFactory.createParticipantShape(target);
    } else {
      newElement = elementFactory.create('shape', target);
    }

    // use last mouse event if triggered via keyboard
    if (event instanceof KeyboardEvent) {
      event = mouse.getLastMoveEvent();
    }

    return create.start(event, newElement);
  };
};
