import { combineReducers } from 'redux';
import commonReducer from './common/commonReducer';
import module1Reducer from './module1/reducers/module1Reducer';
import workFlowReducer from './workflow/reducer';
import AppReducer from './app/reducer';
import FormReducer from './form/reducer';


const rootReducer = combineReducers({
  common: commonReducer,
  module1: module1Reducer,
  workFlowReducer: workFlowReducer,
  AppReducer:AppReducer,
  FormReducer:FormReducer
  // Add other module reducers here
});

export default rootReducer;