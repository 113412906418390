import { FormActionTypes } from "./actiontype";

const initialState: any = {
    isLoading: false,
    loading: false,
    items: [],
    errors: {},
    createFormResponse: {},
}

const FormReducer: any = (state = initialState, action: any) => {
    const response = action.data;
    switch (action.type) {
        case FormActionTypes.INIT_FORM_MODULE:
            return {
                ...state, loading: false, isLoading: false, items: [], createFormResponse: {}
            }
        case FormActionTypes.CREATE_FORM_REQUEST:
            return { ...state, loading: true, createFormResponse: {} }
        case FormActionTypes.CREATE_FORM_SUCCESS:
            return { ...state, loading: false, createFormResponse: response }
        case FormActionTypes.CREATE_FORM_FAILURE:
            return { ...state, loading: false, errors: action.error }

        default: {
            return state;
        }
    }
}

export default FormReducer;