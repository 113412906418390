// ColorPickerModule.js

import ColorContextPadProvider from "./ColorContextPadProvider";
import ColorPopupProvider from "./ColorPopupProvider";

const colorPickerModule = {
  __init__: ["colorContextPadProvider", "colorPopupProvider"],
  colorContextPadProvider: ["type", ColorContextPadProvider],
  colorPopupProvider: ["type", ColorPopupProvider],
};

export default colorPickerModule;
