import { takeLatest, put } from 'redux-saga/effects';
import { INCREMENT_COMMON } from './commonActions';

function* handleIncrementCommon() {
  // You can add additional logic here
  yield put({ type: 'INCREMENT_COMMON_SUCCESS' });
}

function* commonSaga() {
  yield takeLatest(INCREMENT_COMMON, handleIncrementCommon);
}

export default commonSaga;