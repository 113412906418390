import { all } from 'redux-saga/effects';
import commonSaga from './common/commonSaga';
import module1Saga from './module1/sagas/module1Saga';
import workFlowSaga from './workflow/saga';
import appSaga from './app/saga';
import formSaga from './form/saga';



function* rootSaga() {
  yield all([
    commonSaga(),
    module1Saga(),
    workFlowSaga(),
    appSaga(),
    formSaga()
    // Add other module sagas here
  ]);
}

export default rootSaga;