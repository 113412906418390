import { takeEvery, call, put } from "redux-saga/effects";
import { WorkFlowActionTypes } from "./actiontype";
import { baseAPI, fetchData } from '../../../services/api';
import {  toast } from "react-toastify";

import {getWorkFlowDetailSuccess,getWorkFlowDetailFailure,createWorkFlowDetailSuccess,createWorkFlowDetailFailure,createTaskWorkFlowDetailSuccess,createTaskWorkFlowDetailFailure} from "./action";
// function* handleFetchData(action: any) {
//     try {
//       const { endpoint } = action.payload;
//       const data = yield call(fetchData, endpoint);
//       yield put(createWorkFlowDetailSuccess(data));
//     } catch (error) {
//       yield put(fetchDataFailure(error.message));
//     }
//   }

function* workerCreateWorkFlowDetail(action: any) {
    const request = action.data;
    try {
        const response = yield call(baseAPI.post, `workflowsetup/createforxml`, request.data);
      if (response) {
        yield put(createWorkFlowDetailSuccess(response))
      } else {
        yield put(createWorkFlowDetailFailure(response))
      }
    } catch (error) {
      yield put(createWorkFlowDetailFailure(error))
    }
  }

  function* workerCreateTaskWorkFlowDetail(action: any) {
    const request = action.data;
    try {
      const response = yield call(baseAPI.post, `workflowsetup/createworkflowtask`, request.data);
      console.log('response',response)

      if (response) {
        yield put(createTaskWorkFlowDetailSuccess(response))
      } else {
        toast('Internal Server Error');

        yield put(createTaskWorkFlowDetailFailure(response))
      }
    } catch (error) {
      toast('Internal Server Error');

      yield put(createTaskWorkFlowDetailFailure(error))
    }
  }

function* workerGetWorkFlowLists(action) {
  const request = action.data;
  try {
    const response = yield call(baseAPI.post, `workflowsetup/createworkflowtask`, request.data);

    if (response) {

      yield put(getWorkFlowDetailSuccess(response))
    } else {

      yield put(getWorkFlowDetailFailure(response))
    }
  } catch (error) {
    yield put(getWorkFlowDetailFailure(error))
  }
}

  function* workFLowModuleSaga() {
    yield takeEvery(WorkFlowActionTypes.CREATE_WORKFLOW_REQUEST, workerCreateWorkFlowDetail);
    yield takeEvery(WorkFlowActionTypes.CREATE_TASK_WORKFLOW_REQUEST, workerCreateTaskWorkFlowDetail);
    yield takeEvery(WorkFlowActionTypes.GET_WORKFLOW_REQUEST, workerGetWorkFlowLists);
  }
  
  export default workFLowModuleSaga;