import {  passwordType, PasswordRenderer, FileRenderer, RangeRenderer, fileType, rangeType } from './Range';

/*
 * This is the module definition of the custom field. This goes
 * into the Form instance via `additionalModules`.
 */
class CustomFormFields {
  constructor(formFields:any) {
    formFields.register(rangeType, RangeRenderer);
    formFields.register(passwordType, PasswordRenderer);
    formFields.register(fileType, FileRenderer);
  }
}


const CustomFormFieldsModule = {
  __init__: ['rangeField'],
  rangeField: ['type', CustomFormFields],
};

export default CustomFormFieldsModule;