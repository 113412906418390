import React, {useEffect} from "react";
import { Table, Modal, ModalBody, Button, ModalHeader } from "reactstrap";
import BpmnJS from 'bpmn-js/lib/NavigatedViewer';
import TokenSimulationModule from 'bpmn-js-token-simulation/lib/viewer';

const ViewFunction = (props: any) => {

  useEffect(() => {
    console.log(props)
    if (props.xmlData) {
      setTimeout(()=>{
        const rawContainer = document.getElementById("canvas");
        console.log(rawContainer)
      const container = rawContainer as HTMLDivElement;
      const bpmnViewer = new BpmnJS({
        container,
      });
      let xml=props.xmlData; 
      // console.log("Xml...",xml);    
      openDiagram(bpmnViewer, xml);
       
      async function openDiagram(bpmnViewer:any , xml) {
        try {
          // console.log('props.xmlData..',props.xmlData)
          await bpmnViewer.importXML(xml);
    
        } catch (err) {
          console.error('Could not import BPMN 2.0 diagram', err);
        }
      } 
      },100)
         }

  }, [props])
  
  

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.togglehandleViewHistoryModal}
        centered
       size="xl"
        className="border-0"
        modalClassName='modal fade zoomIn'
        backdrop="static"
      >

        <ModalHeader className="p-3 bg-info-subtle mb-2 text-center"
         >
        Work Flow
        </ModalHeader>
        <ModalBody>
        <div id="canvas" style={{height:400}}></div>       


        </ModalBody>
        <div className="modal-footer">
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              onClick={() => {
                props.setOpen(false);
              }}
              className="btn-light"
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>

    </React.Fragment>
  );
};

export default ViewFunction;

