import { AppActionTypes } from "./actiontype"

export const initAppModuleAction = () => ({

    type: AppActionTypes.INIT_APP_MODULE
})

export const createAppDetailAction = (data: any) => ({
    type: AppActionTypes.CREATE_APP_REQUEST,
    data
})

export const createAppDetailSuccess = (data: any) => ({
    type: AppActionTypes.CREATE_APP_SUCCESS,
    data
})

export const createAppDetailFailure = (error: any) => ({
    type: AppActionTypes.CREATE_APP_FAILURE,
    error
})