import React, {  useRef, useState } from 'react';
import BpmnJS from 'bpmn-js/lib/NavigatedViewer';
import Navbar from "../Navbar";
import TokenSimulationModule from 'bpmn-js-token-simulation/lib/viewer';
import "bpmn-js-token-simulation/assets/css/bpmn-js-token-simulation.css";



function BpmnViewer() {
    const fileInputRef = useRef(null);
    const [fileSelected, setFileSelected] = useState(false);
    

  async function openDiagram(bpmnXML:any, bpmnViewer:any) {
    try {
      await bpmnViewer.importXML(bpmnXML);

    } catch (err) {
      console.error('Could not import BPMN 2.0 diagram', err);
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  
  const file = event.target.files?.[0];
  const rawContainer = document.getElementById("canvas");
  const container = rawContainer as HTMLDivElement;

  if (file) {
    const reader = new FileReader();

    reader.onload = (event) => {
      const bpmnXML = event.target?.result as string;
      const bpmnViewer = new BpmnJS({
        container,
        additionalModules: [TokenSimulationModule],
      });
      openDiagram(bpmnXML, bpmnViewer);
      setFileSelected(true);
    };
    reader.readAsText(file);
    }
    
};

  return (
    <div>
      {/* <Navbar/>       */}
      <div id="canvas" className={fileSelected ? "viewer1" : "viewer2"}></div>       
          {!fileSelected &&
              <label className="file-input" htmlFor="file">
              <div className="drop-zone">
                <p className="text">
                  <b>Select a file</b> or drop the  <b> WorkFlow! </b>
                </p>
              </div>
              <input
                type="file"
                id="file"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </label>                             
          }
    </div>
  );
}

export default BpmnViewer;
