import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TiThMenu } from 'react-icons/ti';
import '../Assets/style/Layout.css';
import { useMsal } from '@azure/msal-react';
import { FcWorkflow } from "react-icons/fc";
import { LuLayoutDashboard } from "react-icons/lu";
import { SiGoogleforms } from "react-icons/si";
import { VscPreview } from "react-icons/vsc";
import { MdPreview } from "react-icons/md";
import { FcList } from "react-icons/fc";
import { MdCreateNewFolder } from "react-icons/md";

const Layout = ({ children }) => {
  const [isSidebarActive, setSidebarActive] = useState(false);
  const [activeLink, setActiveLink] = useState(() => localStorage.getItem('activeLink') || 'Dashboard');
  const [open, setOpen] = useState(() => localStorage.getItem('Open') || 'open');
  const { instance } = useMsal();

  useEffect(() => {
    localStorage.setItem('activeLink', activeLink);
  }, [activeLink]);

  useEffect(() => {
    localStorage.setItem('Open', open);
  }, [open]);

  const toggleSidebar = () => {
    setSidebarActive(!isSidebarActive);
    const updatedOpen = open === 'close' ? 'open' : 'close';
    setOpen(updatedOpen);
    localStorage.setItem('Open', updatedOpen);
  };

  const handleLogout = () => {
    instance.logout();
  };

  return (
    <div className="App">
      <div className={`sidebar ${isSidebarActive ? 'active' : ''}`}>
        <div className="logo-details">
        <i></i>
          <span className="logo_name">IZ-WORKFLOW</span>
        </div>
        <ul className="nav-links" style={{ paddingLeft: "0" }}>
          <li>
            <Link to="/" className={activeLink === 'Dashboard' ? 'active' : ''} onClick={() => setActiveLink('Dashboard')}>
              <i><LuLayoutDashboard className='bx bx-box fs-2'/></i>
              <span className="links_name ms-4">Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/apps" className={activeLink === 'Apps' ? 'active' : ''} onClick={() => setActiveLink('Apps')}>
              <i><MdCreateNewFolder className='bx bx-box fs-2'/></i>
              <span className="links_name ms-4">App</span>
            </Link>
          </li>
          {/* <li>
              <Link to="/bpmn" className={activeLink === 'WorkFlow' ? 'active' : ''} onClick={() => setActiveLink('WorkFlow')}>
              <i><FcWorkflow className='bx bx-box fs-2'/></i>
                <span className="links_name ms-4">Work Flow Editor</span>
              </Link>
            </li> */}
            <li>
            <Link to="/workflowgrid" className={activeLink === 'WorkFlowGrid' ? 'active' : ''} onClick={() => setActiveLink('WorkFlowGrid')}>
              <i><FcList className='bx bx-box fs-2' /></i>
                <span className="links_name ms-4">Work Flows</span>
                </Link>
            </li> 
            
          {/* <li>
            <Link to="/form" className={activeLink === 'Form' ? 'active' : ''} onClick={() => setActiveLink('Form')}>
              <i><SiGoogleforms className='bx bx-box fs-2 text-success'/></i>
              <span className="links_name ms-4">Form</span>
            </Link>
          </li> */}
          <li>
            <Link to="/BpmnViewer" className={activeLink === 'WorkFlowPreview' ? 'active' : ''} onClick={() => setActiveLink('WorkFlowPreview')}>
              <i><VscPreview className='bx bx-box fs-2 text-warning'/></i>
              <span className="links_name ms-4">Work Flow Preview</span>
            </Link>
          </li>
          <li>
            <Link to="/formViewer" className={activeLink === 'FormPreview' ? 'active' : ''} onClick={() => setActiveLink('FormPreview')}>
              <i><MdPreview className='bx bx-box fs-2 text-info'/></i>
              <span className="links_name ms-4">Form Preview</span>
            </Link>
          </li>
        
        </ul>
      </div>
      <section className="home-section">
        <nav>
          <div className="sidebar-button">
            <TiThMenu className='fs-2' onClick={toggleSidebar} />
            <span className="Dashboard ms-2">{activeLink }</span>
          </div>
          <div className='d-flex align-items-center'>
            <div className="profile-details me-3">
              <span className="admin_name">User</span>
              <i className='bx bx-chevron-down' ></i>
            </div>
            <div className="log_out">
              <button className="btn btn-primary" >Log Out</button>
              <i className='bx bx-chevron-down' ></i>
            </div>
          </div>
        </nav>
      </section>
      <section className="home-section">
        <div className="home-content">
          {children}
        </div>
      </section>
    </div>
  );
};

export default Layout;
