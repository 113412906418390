import axios from 'axios';
import { api } from "../config";

const baseURL = 'http://localhost:3000'; // Replace with your API base URL

axios.defaults.baseURL = api.API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const fetchData = async (endpoint: string) => {
  try {
    const response = await api.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const baseAPI = axios.create({
    baseURL: api.API_URL,
    withCredentials: true
  });
  
  class APIClient {
    /**
     * Fetches data from given url
     */
  
    get = (url, params) => {
      let response;
  
      let paramKeys:any = [];
  
      if (params) {
        Object.keys(params).map((key:any) => { //NOSONAR
          paramKeys.push(key + '=' + params[key]); //NOSONAR
          return paramKeys; //NOSONAR
        });
  
        const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
        response = axios.get(`${url}?${queryString}`, params);
      } else {
        response = axios.get(`${url}`, params);
      }
  
      return response;
    };
    /**
     * post given data to url
     */
    create = (url, data) => {
      return axios.post(url, data);
    };
    /**
     * Updates data
     */
    update = (url, data) => {
      return axios.patch(url, data);
    };
  
    put = (url, data) => {
      return axios.put(url, data);
    };
    /**
     * Delete
     */
    delete = (url, config) => {
      return axios.delete(url, { ...config });
    };
  }
  export { APIClient };