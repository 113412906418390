export enum WorkFlowActionTypes {
    INIT_WORKFLOW_MODULE = "INIT_WORKFLOW_MODULE",
    CREATE_WORKFLOW_REQUEST = "CREATE_WORKFLOW_REQUEST",
    CREATE_WORKFLOW_SUCCESS = "CREATE_WORKFLOW_SUCCESS",
    CREATE_WORKFLOW_FAILURE = "CREATE_WORKFLOW_FAILURE",
    CREATE_TASK_WORKFLOW_REQUEST = "CREATE_TASK_WORKFLOW_REQUEST",
    CREATE_TASK_WORKFLOW_SUCCESS = "CREATE_TASK_WORKFLOW_SUCCESS",
    CREATE_TASK_WORKFLOW_FAILURE = "CREATE_TASK_WORKFLOW_FAILURE",
    GET_WORKFLOW_REQUEST = "GET_WORKFLOW_REQUEST",
    GET_WORKFLOW_SUCCESS = "GET_WORKFLOW_SUCCESS",
    GET_WORKFLOW_FAILURE = "GET_WORKFLOW_FAILURE",
}
