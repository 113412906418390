import React, { useEffect, useRef, useState } from 'react';
import { FormPlayground } from '@bpmn-io/form-js';
import '@bpmn-io/form-js/dist/assets/form-js.css';
import '@bpmn-io/form-js/dist/assets/form-js-editor.css';
import '@bpmn-io/form-js/dist/assets/form-js-playground.css';
import CustomFormFieldsModule from './extension/render/index';
import PropertiesPanelExtension from './extension/propertiesPanel/index';
import schema from './empty.json';
import Navbar from '../Navbar';
import { useNavigate,useLocation  } from 'react-router-dom';import "../Assets/style/design.css"
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { connect } from "react-redux";
import { initFormModuleAction ,createFormDetailAction} from "../test/store/actions";
import { ToastContainer,toast } from 'react-toastify';
const FormPlaygroundComponent = (props: any) => {
  const formContainerRef = useRef(null);
  const formRendererRef = useRef<FormPlayground | null>(null);
  const [formData, setFormData] = useState({});
  const location = useLocation()
  let { appID } = location.state || {};
  const {createFormResponse } = props;  
  const navigate = useNavigate();

  useEffect(() => {
      // props.initAppModuleAction();
      if(createFormResponse && createFormResponse.data){
        toast('Form Created Successfully');
          navigate('/bpmn',{ state: { formResponse: createFormResponse.data} });  
          props.initFormModuleAction()
      }        
      
    }, [createFormResponse])
  useEffect(() => {
    const container = formContainerRef.current;
    if (!container) {
      console.error('Form container not found.');
      return;
    }

    const formRenderer = new FormPlayground({
      container,
      schema,
      data: {},
      additionalModules: [CustomFormFieldsModule],
      // editorAdditionalModules: [PropertiesPanelExtension],
    });
    formRendererRef.current = formRenderer;

    return () => {
      // Clean up resources on component unmount
      props.initFormModuleAction()
      formRenderer.destroy();
    };
  }, []);

  const handleCreateButtonClick = () => {
    const container = formRendererRef.current?.getState();
    const formData=container?.schema
    console.log('testing....',formData);
    props.createFormDetailAction({ data: formData,appId:appID.id })
  };

  return (
    <React.Fragment>
      <Card>
      <CardHeader className='text-end'>
      <Button color="success" className="add-btn mb-2" id="create-btn" onClick={handleCreateButtonClick}>Create Form</Button>
     </CardHeader> 
      <CardBody>
      <div id="form" ref={formContainerRef} style={{ height: '950px' }}>   
      </div>
      </CardBody>       
      </Card>
      {/* <ToastContainer position="bottom-right"/> */}

      </React.Fragment>
  );
};


const mapStateToProps = (state: any) => ({
  // loading: state.kgReducer?.loading,
  // error: state.kgReducer?.errors,
  // kgListResponse: state.kgReducer?.kgListResponse,
  // kgGridData: state.kgReducer?.kgGridData,
  createFormResponse: state.FormReducer?.createFormResponse,
  // deleteKGResponse: state.kgReducer?.deleteKGResponse,
  // totalElements: state.kgReducer?.totalElements,
  // subFunctionResponse: state.kgReducer?.subFunctionListDataResponse,
})

const mapDispatchToProps = (dispatch: any) => ({
  initFormModuleAction: () => {
    dispatch(initFormModuleAction())
  },
  createFormDetailAction: (data: any) => {
    dispatch(createFormDetailAction(data))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(FormPlaygroundComponent);
