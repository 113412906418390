import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import AuthProvider from './AuthProvider';
import { Provider } from "react-redux";
import { configureStore } from "./test/store";

const rootElement:HTMLElement | null = document.getElementById('root');

if (rootElement) {
  const root: ReactDOM.Root = ReactDOM.createRoot(rootElement);
  root.render(
    // <AuthProvider>
    // <App />
    // </AuthProvider>
    <Provider store={configureStore({})}>
       <App />  
       </Provider>
  );
} else {
  console.error("Root element with id 'root' not found");
}


