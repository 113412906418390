import React, { useState, useLayoutEffect, useRef } from "react";
import { createForm } from "@bpmn-io/form-js";
import "@bpmn-io/form-js/dist/assets/form-js.css";
import Navbar from "../Navbar";
// import RenderExtension from "../Form/extension/render/index";

export default function App() {
  const containerRef = useRef(null);
  const fileInputRef = useRef(null);
  const [schema, setSchema] = useState(null);

  useLayoutEffect(() => {
    if (containerRef.current && schema) {
      createForm({
        schema,
        data: { creditor: "Gopi" },
        container: containerRef.current,
        // additionalModules: [RenderExtension],
      });
    }
  }, [schema]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const fileContent = JSON.parse(event.target?.result as string);
          setSchema(fileContent);
          console.log(fileContent);
        } catch (error) {
          console.error("Error parsing file content:", error);
        }
      };

      reader.readAsText(file);
    }
  };

  const isFileSelected = schema !== null;

  return (
    <div>
      {/* <Navbar /> */}
      {!isFileSelected && (
        <label className="file-input" htmlFor="file">
          <div className="drop-zone">
            <p className="text">
            <b>Select a file</b> or drop the  <b> Form! </b>
            </p>
          </div>
          <input
            type="file"
            id="file"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </label>
      )}
      {isFileSelected && (
        <>
          <div ref={containerRef} className="viewer">
            {/* Form will be rendered here */}
          </div>
          {/* <button onClick={() => window.location.reload()} className="btn btn-info shadow-lg p-2 m-5  bg-white rounded">Add A New File</button> */}
        </>
      )}
    </div>
  );
}
