import { takeEvery, call, put } from "redux-saga/effects";
import { AppActionTypes } from "./actiontype";
import { baseAPI, fetchData } from '../../../services/api';
import {  toast } from "react-toastify";
import {createAppDetailSuccess,createAppDetailFailure} from "./action";
function* workerCreateAppDetail(action: any) {
    const request = action.data;
    try {
        const response = yield call(baseAPI.post, `workflowsetup/app/create`, request.data);
      if (response) {
        // toast('App Created Successfully');

        yield put(createAppDetailSuccess(response))
      } else {
        toast('Internal Server Error');
        yield put(createAppDetailFailure(response))
      }
    } catch (error) {
        toast('Internal Server Error');
      yield put(createAppDetailFailure(error))
    }
  }

  function* AppModuleSaga() {
    yield takeEvery(AppActionTypes.CREATE_APP_REQUEST, workerCreateAppDetail);   
  }
  
  export default AppModuleSaga;