import classNames from 'classnames';

import {
  
  Numberfield,
  Description,
  Label,
  Errors,
  FormContext
} from '@bpmn-io/form-js';

import {
  html, useContext,
  
} from 'diagram-js/lib/ui';

import './styles.css';

export const rangeType = 'range';
export const passwordType = 'password'; // New password field type
export const fileType = 'file'; // New file field type


export function RangeRenderer(props) {

  const {
    disabled,
    errors = [],
    field,
    readonly,
    value
  } = props;

  const {
    description,
    range = {},
    id,
    label
  } = field;

  const {
    min,
    max,
    step
  } = range;

  const { formId } = useContext(FormContext);

  const errorMessageId = errors.length === 0 ? undefined : `${prefixId(id, formId)}-error-message`;

  const onChange = ({ target }) => {
    props.onChange({
      field,
      value: Number(target.value)
    });
  };

  return html`<div class=${formFieldClasses(rangeType)}>
    <${Label}
      id=${prefixId(id, formId)}
      label=${label} />
    <div class="range-group">
      <input
        type="range"
        disabled=${disabled}
        id=${prefixId(id, formId)}
        max=${max}
        min=${min}
        onInput=${onChange}
        readonly=${readonly}
        value=${value}
        step=${step} />
      <div class="range-value">${value}</div>
    </div>
    <${Description} description=${description} />
    <${Errors} errors=${errors} id=${errorMessageId} />
  </div>`;
}

export function PasswordRenderer(props:any) {

  const {
    disabled,
   
    field,
    readonly,
    value
  } = props;

  const {
    description,
    
    label
  } = field;

  const { formId } = useContext(FormContext);

  // const errorMessageId = errors.length === 0 ? undefined : `${prefixId(id, formId)}-error-message`;

  const onChange = ({ target }) => {
    props.onChange({
      field,
      value: target.value
    });
  };

  return html`<div >
    <${Label}
      
      label=${label} />
    <div class="password-group">
      <input
        type="password"
        disabled=${disabled}
       
        onInput=${onChange}
        readonly=${readonly}
        value=${value}
      />
      
    </div>
    
    <${Description} description=${description} />
  </div>`;
}

export function FileRenderer(props) {
  const {
    disabled,
    errors = [],
    field,
    readonly,
    value
  } = props;

  const {
    description,
    id,
    label
  } = field;

  const { formId } = useContext(FormContext);

  const errorMessageId = errors.length === 0 ? undefined : `${prefixId(id, formId)}-error-message`;

  const onChange = ({ target }) => {
    props.onChange({
      field,
      value: target.value
    });
  };

  return html`<div class=${formFieldClasses(fileType)}>
    
    <div class="file-group">
      <input
        type="file"
        disabled=${disabled}
        id=${prefixId(id, formId)}
        onChange=${onChange}
        readonly=${readonly}
        value=${value}
      />
      
    </div>
    <${Description} description=${description} />
    <${Errors} errors=${errors} id=${errorMessageId} />
  </div>`;
}

const encodedSVG = btoa('<svg width="800" height="800" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"><path d="M8.042 29h56M8.042 26v8M64.042 26v8M36.042 26v8M22.042 26v6M50.042 26v6M8.008 45.905h0a1.916 1.916 0 0 1-1.915-1.915v-3.17c0-1.057.858-1.915 1.915-1.915h0c1.059 0 1.916.858 1.916 1.916v3.169a1.916 1.916 0 0 1-1.915 1.915zM61.833 40.612a2.151 2.151 0 0 1 2.107-1.717h0c.594 0 1.131.24 1.52.63.606.605.55 1.61-.018 2.251l-3.652 4.119h4.3M35.121 40.412l1.938-1.451v7"/></g></svg>');


RangeRenderer.config = {
  ...Numberfield.config,
  type: rangeType,
  label: 'Range',
  iconUrl: `data:image/svg+xml;base64,${encodedSVG}`,
  propertiesPanelEntries: [
    'key',
    'label',
    'description',
    'min',
    'max',
    'disabled',
    'readonly'
  ]
};

const encodedSVG1 = btoa('<?xml version="1.0" encoding="utf-8"?><svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 6V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V6M9 11H15C15.5523 11 16 10.5523 16 10V7C16 6.44772 15.5523 6 15 6H9C8.44772 6 8 6.44772 8 7V10C8 10.5523 8.44772 11 9 11ZM5 21H19C20.1046 21 21 20.1046 21 19V16C21 14.8954 20.1046 14 19 14H5C3.89543 14 3 14.8954 3 16V19C3 20.1046 3.89543 21 5 21Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><circle cx="7.5" cy="17.5" r="1.5" fill="#000000"/><circle cx="12" cy="17.5" r="1.5" fill="#000000"/><circle cx="16.5" cy="17.5" r="1.5" fill="#000000"/></svg>');


PasswordRenderer.config = {
  ...Numberfield.config,
  type: passwordType,
  label: 'Password',
  iconUrl: `data:image/svg+xml;base64,${encodedSVG1}`,
  propertiesPanelEntries: [
    'key',
    'label',
    'description',
    'disabled',
    'readonly'
  ]
};

const encodedSVG2 = btoa('<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="File / File_Upload"><path id="Vector" d="M12 18V12M12 12L9 14M12 12L15 14M13 3.00087C12.9045 3 12.7973 3 12.6747 3H8.2002C7.08009 3 6.51962 3 6.0918 3.21799C5.71547 3.40973 5.40973 3.71547 5.21799 4.0918C5 4.51962 5 5.08009 5 6.2002V17.8002C5 18.9203 5 19.4801 5.21799 19.9079C5.40973 20.2842 5.71547 20.5905 6.0918 20.7822C6.51921 21 7.079 21 8.19694 21L15.8031 21C16.921 21 17.48 21 17.9074 20.7822C18.2837 20.5905 18.5905 20.2842 18.7822 19.9079C19 19.4805 19 18.9215 19 17.8036V9.32568C19 9.20296 19 9.09561 18.9991 9M13 3.00087C13.2856 3.00347 13.4663 3.01385 13.6388 3.05526C13.8429 3.10425 14.0379 3.18526 14.2168 3.29492C14.4186 3.41857 14.5918 3.59182 14.9375 3.9375L18.063 7.06298C18.4089 7.40889 18.5809 7.58136 18.7046 7.78319C18.8142 7.96214 18.8953 8.15726 18.9443 8.36133C18.9857 8.53376 18.9963 8.71451 18.9991 9M13 3.00087V5.8C13 6.9201 13 7.47977 13.218 7.90759C13.4097 8.28392 13.7155 8.59048 14.0918 8.78223C14.5192 9 15.079 9 16.1969 9H18.9991M18.9991 9H19.0002" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg>');


FileRenderer.config = {
  ...Numberfield.config,
  type: fileType,
  label: 'File',
  iconUrl: `data:image/svg+xml;base64,${encodedSVG2}`,
  propertiesPanelEntries: [
    'key',
    'label',
    'description',
    'disabled',
    'readonly'
  ]
};


function formFieldClasses(type, { errors = [], disabled = false, readonly = false } = {}) {
  if (!type) {
    throw new Error('type required');
  }

  return classNames('fjs-form-field', `fjs-form-field-${type}`, {
    'fjs-has-errors': errors.length > 0,
    'fjs-disabled': disabled,
    'fjs-readonly': readonly
  });
}

function prefixId(id, formId) {
  if (formId) {
    return `fjs-form-${formId}-${id}`;
  }

  return `fjs-form-${id}`;
}

