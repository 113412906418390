import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";

import {
    Container,
    Button,
    Card,
    CardBody,
    Col,
    Row,
    CardHeader,
} from "reactstrap";
import CreateApp from "./createApp";
import { connect } from "react-redux";
import {  toast } from "react-toastify";

import { initAppModuleAction,createAppDetailAction } from "../test/store/actions";
import { ToastContainer } from "react-toastify";
const Apps = (props:any) => {
    const {createAppResponse } = props;  
    const navigate = useNavigate();

    useEffect(() => {
        // props.initAppModuleAction();
        if(createAppResponse && createAppResponse.data){
            toast('App Created Successfully');

            navigate('/form',{ state: { appID: createAppResponse.data} });  
            props.initAppModuleAction()
        }        
        
      }, [createAppResponse])

    const handleSubmit = (values, { setSubmitting }) => {
        // Handle your form submission logic here
        // For example, you can navigate or call an API
        console.log('values..',values)
        setModal(false);
        props.createAppDetailAction({ data: values })       
        setSubmitting(false);
      };

    const sortbyname: any = [
        {
            options: [
                { label: "All", value: "All" },
                { label: "Today", value: "Today" },
                { label: "Yesterday", value: "Yesterday" },
                { label: "Last 7 Days", value: "Last 7 Days" },
                { label: "Last 30 Days", value: "Last 30 Days" },
                { label: "Thise Month", value: "Thise Month" },
                { label: "Last Year", value: "Last Year" },
            ],
        },
    ];
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const toggle = () => {
        if (modal) {
          setModal(false);
        //   setTask(null);
        } else {
          setModal(true);
        //   setDate(defaultdate());
        }
      };
    return (
        <React.Fragment>
            <div className="page-content  fs-6">
                <Container fluid>
                    {/* <Row id="job-list">
                        <Col className='mt-3 mb-2' id="job-widget">
                            <Card className="card-height-100 bg-info bg-job">
                                <CardBody className='p-5'>
                                    <h2 className="lh-base text-white">
                                        IZ-APPLICATION MANAGER
                                    </h2>
                                    <p className="text-white text-opacity-75 mb-0 fs-14">
                                       Click Create Button To Create The Application
                                    </p>
                                    <div className="mt-5 pt-2">
                                        <Button type="button" className="btn btn-light w-100"  onClick={() => { setIsEdit(false); toggle(); }}>
                                        Create
                                            <i className="ri-arrow-right-line align-bottom"></i>
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={6}  className='mt-3'>
                            <Card>
                                <CardBody>

                                    <h4>
                                        <h5>{"Application Name"}</h5>
                                    </h4>
                                    <p className="text-muted">{"Customer"} </p>

                                    <p className="text-muted">{"As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."}</p>

                                    <React.Fragment >
                                        <Row className="gy-3">
                                            <Col xs={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Status</p>
                                                    <div className={"fs-12 badge bg-success"}>Completed</div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Created On</p>
                                                    <h6 className="fs-12">18 May, 2021</h6>
                                                </div>
                                            </Col>
                                        </Row>

                                    </React.Fragment>

                                    <div className="mt-4 hstack gap-2">
                                        <Button type="button" className="btn btn-warning w-100">
                                        Edit
                                        </Button>
                                        <Button type="button" className="btn btn-primary w-100">
                                            Overview
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={6}  className='mt-3'>
                            <Card>
                                <CardBody>

                                    <h4>
                                        <h5>{"Application Name"}</h5>
                                    </h4>
                                    <p className="text-muted">{"Customer"} </p>

                                    <p className="text-muted">{"As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."}</p>

                                    <React.Fragment >
                                        <Row className="gy-3">
                                            <Col xs={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Status</p>
                                                    <div className={"fs-12 badge bg-success"}>Completed</div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Created On</p>
                                                    <h6 className="fs-12">18 May, 2021</h6>
                                                </div>
                                            </Col>
                                        </Row>

                                    </React.Fragment>

                                    <div className="mt-4 hstack gap-2">
                                        <Button type="button" className="btn btn-warning w-100">
                                        Edit
                                        </Button>
                                        <Button type="button" className="btn btn-primary w-100">
                                            Overview
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={6}  className='mt-3'>
                            <Card>
                                <CardBody>

                                    <h4>
                                        <h5>{"Application Name"}</h5>
                                    </h4>
                                    <p className="text-muted">{"Customer"} </p>

                                    <p className="text-muted">{"As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."}</p>

                                    <React.Fragment >
                                        <Row className="gy-3">
                                            <Col xs={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Status</p>
                                                    <div className={"fs-12 badge bg-success"}>Completed</div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Created On</p>
                                                    <h6 className="fs-12">18 May, 2021</h6>
                                                </div>
                                            </Col>
                                        </Row>

                                    </React.Fragment>

                                    <div className="mt-4 hstack gap-2">
                                        <Button type="button" className="btn btn-warning w-100">
                                        Edit
                                        </Button>
                                        <Button type="button" className="btn btn-primary w-100">
                                            Overview
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={6}  className='mt-3'>
                            <Card>
                                <CardBody>

                                    <h4>
                                        <h5>{"Application Name"}</h5>
                                    </h4>
                                    <p className="text-muted">{"Customer"} </p>

                                    <p className="text-muted">{"As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."}</p>

                                    <React.Fragment >
                                        <Row className="gy-3">
                                            <Col xs={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Status</p>
                                                    <div className={"fs-12 badge bg-success"}>Completed</div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Created On</p>
                                                    <h6 className="fs-12">18 May, 2021</h6>
                                                </div>
                                            </Col>
                                        </Row>

                                    </React.Fragment>

                                    <div className="mt-4 hstack gap-2">
                                        <Button type="button" className="btn btn-warning w-100">
                                        Edit
                                        </Button>
                                        <Button type="button" className="btn btn-primary w-100">
                                            Overview
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={6}  className='mt-3'>
                            <Card>
                                <CardBody>

                                    <h4>
                                        <h5>{"Application Name"}</h5>
                                    </h4>
                                    <p className="text-muted">{"Customer"} </p>

                                    <p className="text-muted">{"As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."}</p>

                                    <React.Fragment >
                                        <Row className="gy-3">
                                            <Col xs={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Status</p>
                                                    <div className={"fs-12 badge bg-success"}>Completed</div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Created On</p>
                                                    <h6 className="fs-12">18 May, 2021</h6>
                                                </div>
                                            </Col>
                                        </Row>

                                    </React.Fragment>

                                    <div className="mt-4 hstack gap-2">
                                        <Button type="button" className="btn btn-warning w-100">
                                        Edit
                                        </Button>
                                        <Button type="button" className="btn btn-primary w-100">
                                            Overview
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={6}  className='mt-3'>
                            <Card>
                                <CardBody>

                                    <h4>
                                        <h5>{"Application Name"}</h5>
                                    </h4>
                                    <p className="text-muted">{"Customer"} </p>

                                    <p className="text-muted">{"As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."}</p>

                                    <React.Fragment >
                                        <Row className="gy-3">
                                            <Col xs={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Status</p>
                                                    <div className={"fs-12 badge bg-success"}>Completed</div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Created On</p>
                                                    <h6 className="fs-12">18 May, 2021</h6>
                                                </div>
                                            </Col>
                                        </Row>

                                    </React.Fragment>

                                    <div className="mt-4 hstack gap-2">
                                        <Button type="button" className="btn btn-warning w-100">
                                        Edit
                                        </Button>
                                        <Button type="button" className="btn btn-primary w-100">
                                            Overview
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={6}  className='mt-3'>
                            <Card>
                                <CardBody>

                                    <h4>
                                        <h5>{"Application Name"}</h5>
                                    </h4>
                                    <p className="text-muted">{"Customer"} </p>

                                    <p className="text-muted">{"As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."}</p>

                                    <React.Fragment >
                                        <Row className="gy-3">
                                            <Col xs={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Status</p>
                                                    <div className={"fs-12 badge bg-success"}>Completed</div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6}>
                                                <div>
                                                    <p className="text-muted mb-1">Created On</p>
                                                    <h6 className="fs-12">18 May, 2021</h6>
                                                </div>
                                            </Col>
                                        </Row>

                                    </React.Fragment>

                                    <div className="mt-4 hstack gap-2">
                                        <Button type="button" className="btn btn-warning w-100">
                                            Edit
                                        </Button>
                                        <Button type="button" className="btn btn-primary w-100">
                                            Overview
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>                        
                    </Row>             */}
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">Add, Edit & Remove</h5>
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div>
                                                    <Button color="success" className="add-btn me-1" id="create-btn" onClick={() => { setIsEdit(false); toggle(); }}>+Add</Button>
                                                   
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Search..." />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                       
                                                        <th className="sort" data-sort="customer_name">Name</th>
                                                        <th className="sort" data-sort="email">Project Type</th>
                                                        <th className="sort" data-sort="date">Created Date</th>
                                                        <th className="sort" data-sort="status">Delivery Status</th>
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    <tr>
                                                        
                                                        <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2101</Link></td>
                                                        <td className="customer_name">Mary Cousar</td>
                                                        <td className="email">marycousar@velzon.com</td>
                                                        <td className="date">06 Apr, 2021</td>
                                                        <td className="status"><span className="badge bg-success-subtle text-success text-uppercase">Active</span></td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-success edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2102</Link></td>
                                                        <td className="customer_name">Jeff Taylor</td>
                                                        <td className="email">jefftaylor@velzon.com</td>
                                                        <td className="date">15 Feb, 2021</td>
                                                        <td className="status"><span className="badge bg-success-subtle text-success text-uppercase">Active</span></td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-success edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                       
                                                        <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2103</Link></td>
                                                        <td className="customer_name">Robert McMahon</td>
                                                        <td className="email">robertmcmahon@velzon.com</td>
                                                        <td className="date">12 Jan, 2021</td>
                                                        <td className="status"><span className="badge bg-success-subtle text-success text-uppercase">Active</span></td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-success edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2104</Link></td>
                                                        <td className="customer_name">Michael Morris</td>
                                                        <td className="email">michaelmorris@velzon.com</td>
                                                        <td className="date">19 May, 2021</td>
                                                        <td className="status"><span className="badge bg-danger-subtle text-danger text-uppercase">Block</span></td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-success edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                       
                                                        <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2105</Link></td>
                                                        <td className="customer_name">Kevin Dawson</td>
                                                        <td className="email">kevindawson@velzon.com</td>
                                                        <td className="date">14 Apr, 2021</td>
                                                        <td className="status"><span className="badge bg-success-subtle text-success text-uppercase">Active</span></td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-success edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2106</Link></td>
                                                        <td className="customer_name">Carolyn Jones</td>
                                                        <td className="email">carolynjones@velzon.com</td>
                                                        <td className="date">07 Jun, 2021</td>
                                                        <td className="status"><span className="badge bg-success-subtle text-success text-uppercase">Active</span></td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-success edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                       
                                                        <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2107</Link></td>
                                                        <td className="customer_name">Glen Matney</td>
                                                        <td className="email">glenmatney@velzon.com</td>
                                                        <td className="date">02 Nov, 2021</td>
                                                        <td className="status"><span className="badge bg-success-subtle text-success text-uppercase">Active</span></td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-success edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                      
                                                        <td className="id" style={{ display: "none" }}><Link to="#"
                                                            className="fw-medium link-primary">#VZ2108</Link></td>
                                                        <td className="customer_name">Charles Kubik</td>
                                                        <td className="email">charleskubik@velzon.com</td>
                                                        <td className="date">25 Sep, 2021</td>
                                                        <td className="status"><span className="badge bg-danger-subtle text-danger text-uppercase">Block</span></td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-success edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                       
                                                        <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2109</Link></td>
                                                        <td className="customer_name">Herbert Stokes</td>
                                                        <td className="email">herbertstokes@velzon.com</td>
                                                        <td className="date">20 Jul, 2021</td>
                                                        <td className="status"><span className="badge bg-danger-subtle text-danger text-uppercase">Block</span></td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-success edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2110</Link></td>
                                                        <td className="customer_name">Timothy Smith</td>
                                                        <td className="email">timothysmith@velzon.com</td>
                                                        <td className="date">13 Dec, 2021</td>
                                                        <td className="status"><span className="badge bg-success-subtle text-success text-uppercase">Active</span></td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-success edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                       
                                                        <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2111</Link></td>
                                                        <td className="customer_name">Johnny Evans</td>
                                                        <td className="email">johnnyevans@velzon.com</td>
                                                        <td className="date">01 Oct, 2021</td>
                                                        <td className="status"><span className="badge bg-danger-subtle text-danger text-uppercase">Block</span></td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-success edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                       
                                                        <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2112</Link></td>
                                                        <td className="customer_name">Kevin Dawson</td>
                                                        <td className="email">kevindawson@velzon.com</td>
                                                        <td className="date">14 Apr, 2021</td>
                                                        <td className="status"><span className="badge bg-success-subtle text-success text-uppercase">Active</span></td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-success edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    {/* <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon> */}
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <CreateApp handleSubmit={handleSubmit} modal={modal} toggle={toggle} setModal={setModal}/>
        </React.Fragment>
    );
};
const mapStateToProps = (state: any) => ({
    // loading: state.kgReducer?.loading,
    // error: state.kgReducer?.errors,
    // kgListResponse: state.kgReducer?.kgListResponse,
    // kgGridData: state.kgReducer?.kgGridData,
    createAppResponse: state.AppReducer?.createAppResponse,
    // deleteKGResponse: state.kgReducer?.deleteKGResponse,
    // totalElements: state.kgReducer?.totalElements,
    // subFunctionResponse: state.kgReducer?.subFunctionListDataResponse,
  })
  
  const mapDispatchToProps = (dispatch: any) => ({
    initAppModuleAction: () => {
      dispatch(initAppModuleAction())
    },
    createAppDetailAction: (data: any) => {
      dispatch(createAppDetailAction(data))
    }
  })
  export default connect(mapStateToProps, mapDispatchToProps)(Apps);