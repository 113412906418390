import React, { useEffect, useState, useRef } from "react";
import Modeler from "bpmn-js/lib/Modeler";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import minimapModule from "diagram-js-minimap";
import "diagram-js-minimap/assets/diagram-js-minimap.css";
import ColorPickerModule from "./Color/ColorPickerModule";
import ResizeTask from "./Resize";
import {
  BpmnPropertiesPanelModule,
  BpmnPropertiesProviderModule,
  CamundaPlatformPropertiesProviderModule
} from 'bpmn-js-properties-panel';

import camundaModdleDescriptors from 'camunda-bpmn-moddle/resources/camunda.json';

import "../Assets/style/dashboard.css";
import "../Assets/style/design.css"
import "../Assets/style/index.css"
import "../Assets/style/App.css"
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer ,toast} from 'react-toastify';


import createAppendAnything from "./lib/create-append-anything";

import convert from 'xml-js';
import Navbar from "../Navbar";

import gridModule from 'diagram-js-grid';

import lintModule from "bpmn-js-bpmnlint";
import "bpmn-js-bpmnlint/dist/assets/css/bpmn-js-bpmnlint.css";
import { xml2json } from "xml-js";
import bpmnlintConfig from "./general/bundled-config";
import transform from "./general/transform.xslt";
import {initWorkFlowModuleAction,createWorkFlowDetailAction,createTaskWorkFlowDetailAction } from '../test/store/actions';
import { useLocation,useNavigate } from "react-router-dom";
import { log } from "console";

const App = (props: any) => {
  const [modeler, setModeler] = useState<Modeler | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const newModelerRef = useRef<Modeler | null>(null);
  const [propertyPanelVisible, setPropertyPanelVisible] = useState(true);
  const [taskWorkFlow, settaskWorkFlow] = useState(true);
  const [addedCount, setAddedCount] = useState(0);
  const [removedCount, setRemovedCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [isProcessEmpty, setIsProcessEmpty] = useState(true);
  const location = useLocation()
  let { xml,formResponse } = location.state || {};
  const {createWorkFlowResponse,createTaskWorkFlowResponse } = props;  
  const navigate = useNavigate();

  useEffect(() => {
    console.log(createWorkFlowResponse,createTaskWorkFlowResponse)
    if(createWorkFlowResponse && createWorkFlowResponse.data){
      props.createtaskWorkflowDetailAction({ data: createWorkFlowResponse.data });
      settaskWorkFlow(false)
      props.initWorkflowModuleAction();
    } else if(createTaskWorkFlowResponse && createTaskWorkFlowResponse.data){
      toast(createTaskWorkFlowResponse.data);
      navigate('/workflowgrid');  

      props.initWorkflowModuleAction();
    }
  }, [createWorkFlowResponse,createTaskWorkFlowResponse]);

  useEffect(() => {
    // props.initWorkflowModuleAction()
    
    const rawContainer = containerRef.current;
    const container = rawContainer as HTMLDivElement;

    const newModeler: Modeler = new Modeler({
      container,
      linting: {
        bpmnlint: bpmnlintConfig,
        active: true
      },
      propertiesPanel: {
        parent: document.getElementById('js-properties-panel1')
      },
      additionalModules: [minimapModule, createAppendAnything, ColorPickerModule, ResizeTask,
        BpmnPropertiesPanelModule, BpmnPropertiesProviderModule,
        CamundaPlatformPropertiesProviderModule, gridModule, lintModule],

      taskResizingEnabled: true,
      eventResizingEnabled: true,

      moddleExtensions: {
        camunda: camundaModdleDescriptors
      },

      keyboard: {
        bindTo: document,
      },
    });
    
    const bpmnFilePath = process.env.PUBLIC_URL + "/diagram.bpmn";

    const openDiagram = async () => {
      try {
        if(xml === undefined){
          const response = await fetch(bpmnFilePath);

          if (!response.ok) {
            throw new Error(`Failed to fetch BPMN file: ${response.statusText}`);
          }
          const bpmnXml = await response.text();
  
          await newModeler.importXML(bpmnXml);
        } else {         
          await newModeler.importXML(xml);
        }
      } catch (err) {
        console.error("Error fetching or processing BPMN file:", err);
      }
    };

    const eventBus:any = newModeler.get('eventBus');

    eventBus.on('shape.added', (event: any) => {
      setAddedCount((prevCount) => prevCount + 1);
      setIsProcessEmpty(false);
      const element = event.element.type;

      if (element === 'bpmn:StartEvent' || element === 'bpmn:UserTask') {
        // console.log('clicked');
        FormDataMaping(event)
        FormTypeNameChange(event)
      } 



    });
    
    eventBus.on('element.click', (event) => {
      const element = event.element;
      console.log('Element clicked:', element.type);
      if (element.type === 'bpmn:StartEvent' || element.type === 'bpmn:UserTask') {
        FormDataMaping(event)
        FormTypeNameChange(event)
      }
    });

    eventBus.on('shape.removed', (event: any) => {
      setRemovedCount((prevCount) => prevCount + 1);
      setIsProcessEmpty(newModeler.getDefinitions().rootElements.length === 0);
    });

    eventBus.on('linting.completed', (event) => {
      const { issues } = event;
      const messages = [];
// console.log("Total issues",issues);

    Object.keys(issues).forEach((elementId) => {
      const elementIssues = issues[elementId];

      elementIssues.forEach((issue) => {
        const { message,category } = issue;
        if (category === 'error') {
          messages.push(message);
        }
      });
    });
      setErrorCount(messages.length);
    // console.error('BPMN linting issues:', messages);
    // console.error('BPMN linting issues length :', messages.length);
      
    });

    // function checkDivPresence() {
    //   var yourDiv = document.querySelector('[data-group-id="group-CamundaPlatform__Form"]');
      
    //   if (yourDiv) {
    //     console.log('present');
    //   } else {
    //     console.log('absent');
    //   }
    // }

    // setInterval(checkDivPresence, 1000);




    


    openDiagram();

    setModeler(newModeler);
    newModelerRef.current = newModeler;

    return () => {
      newModeler.destroy();
    };
  }, []);

  useEffect(() => {
    // props.initWorkflowModuleAction()

    const container = containerRef.current;
    function openDiagram(xml:any) {
      const currentModeler = newModelerRef.current;

      if (currentModeler) {
        (currentModeler as any).importXML(xml).then(({ warnings }) => {
          if (warnings.length) {
            // console.log("Warnings", warnings);
          }
        });
      } else {
        console.error("Modeler is not initialized.");
      }
    }

    function registerFileDrop(callback:any) {
      function handleFileSelect(e:any) {
        e.stopPropagation();
        e.preventDefault();

        var files = e.dataTransfer.files;

        var file = files[0];

        var reader = new FileReader();

        reader.onload = function (event) {
          if (event.target) {
            var xml = event.target.result;
            callback(xml);
          } else {
            console.error("FileReader event target is null.");
          }

        };

        reader.readAsText(file);
      }

      function handleDragOver(e:any) {
        e.stopPropagation();
        e.preventDefault();

        e.dataTransfer.dropEffect = "copy";
      }

      if (container) {
        container.addEventListener("dragover", handleDragOver, false);
        container.addEventListener("drop", handleFileSelect, false);
      } else {
        console.error("Container is null.");
      }
    }

    if (!window.FileList || !window.FileReader) {
      window.alert(
        "Looks like you use an older browser that does not support drag and drop. " +
        "Try using Chrome, Firefox, or the Internet Explorer > 10."
      );
    } else {
      registerFileDrop(openDiagram);
    }
  }, []);
  
  const handleDownloadXml = async () => {

    
    

    if (modeler) {
      try {
        const { xml } = await (modeler as any).saveXML({ format: true });

        
        const bpmnObject = {
          data: xml,
        };
        // console.log("bpmnObject",bpmnObject);

        const test = JSON.stringify(bpmnObject, null, 2);
        const parsedObject = JSON.parse(test);
        
        // console.log("data",parsedObject);
        const bpmnRequest = {
          XmlData: xml,
          appId:formResponse.appId,
          formId:formResponse.id
        };
        

        // Download the original XML
        // const blobXml = new Blob([xml], { type: 'application/xml' });
        
        // const linkXml = document.createElement('a');
        // linkXml.href = window.URL.createObjectURL(blobXml);
        // linkXml.download = 'bpmnDiagram.xml';
        // document.body.appendChild(linkXml);
        // linkXml.click();
        // document.body.removeChild(linkXml);

        // // Load the XSLT file using fetch
        // const xsltPath = transform;  // Replace with the actual path to your XSLT file
        // const xsltResponse = await fetch(xsltPath);
        // const xsltText = await xsltResponse.text();

        // // Transform the XML using XSLT
        // const xmlDoc = new DOMParser().parseFromString(xml, 'application/xml');

        // // Check if the XML document is parsed successfully
        // if (xmlDoc.documentElement.nodeName === 'parsererror') {
        //   console.error('Error parsing XML:', xmlDoc.documentElement.textContent);
        //   return;
        // }

        // // XSLT processing
        // const xsltProcessor = new XSLTProcessor();
        // const xsltDoc = new DOMParser().parseFromString(xsltText, 'application/xml');

        // // Check if the XSLT document is parsed successfully
        // if (xsltDoc.documentElement.nodeName === 'parsererror') {
        //   console.error('Error loading XSLT:', xsltDoc.documentElement.textContent);
        //   return;
        // }

        // xsltProcessor.importStylesheet(xsltDoc);

        // // Transform the XML
        // const transformedXmlDoc = xsltProcessor.transformToDocument(xmlDoc);

        // // Check if the transformation is successful
        // if (!transformedXmlDoc || !transformedXmlDoc.documentElement) {
        //   console.error('Error transforming BPMN file:', 'Transformation result is not a valid XML document.');
        //   return;
        // }

        // // Serialize transformed XML only if it is a valid XML node
        // const transformedXml = new XMLSerializer().serializeToString(transformedXmlDoc);
        // if (!transformedXml) {
        //   console.error('Error transforming BPMN file:', 'Failed to serialize transformed XML.');
        //   return;
        // }

        // // Download the transformed XML
        // // const blobTransformedXml = new Blob([transformedXml], { type: 'application/xml' });
        // // const linkTransformedXml = document.createElement('a');
        // // linkTransformedXml.href = window.URL.createObjectURL(blobTransformedXml);
        // // linkTransformedXml.download = 'bpmnDiagram_transformed.xml';
        // // document.body.appendChild(linkTransformedXml);
        // // linkTransformedXml.click();
        // // document.body.removeChild(linkTransformedXml);

        // // for json converter

        // const transformedXml1 = xml2json(transformedXml, { compact: true, spaces: 4 });
        // const items = JSON.parse(transformedXml1);
        // const blobTransformedXml = new Blob([JSON.stringify(items)], { type: 'application/json' });
        // const linkTransformedXml = document.createElement('a');
        // linkTransformedXml.href = window.URL.createObjectURL(blobTransformedXml);
        // linkTransformedXml.download = 'bpmnDiagram_transformed.json';
        // document.body.appendChild(linkTransformedXml);
        // linkTransformedXml.click();
        // document.body.removeChild(linkTransformedXml);
        props.createWorkflowDetailAction({ data: bpmnRequest })
      } catch (err) {
        console.error('Error saving or transforming BPMN file:', err);

        if (err instanceof TypeError) {
          console.error('Type of error:', 'Possibly related to XSLT transformation.');
        }
      }
    } else {
      console.error('Modeler is not initialized.');
    }
  };



  const handleDownloadjson = async  () => {

    if (modeler) {
      try {
        const { xml } = await (modeler as any).saveXML({ format: true });
  
        // Download the original XML
        const blobXml = new Blob([xml], { type: 'application/xml' });
        const linkXml = document.createElement('a');
        linkXml.href = window.URL.createObjectURL(blobXml);
        linkXml.download = 'bpmnDiagram.xml';
        document.body.appendChild(linkXml);
        linkXml.click();
        document.body.removeChild(linkXml);
      } catch (err) {
        console.error('Error saving or transforming BPMN file:', err);
  
        if (err instanceof TypeError) {
          console.error('Type of error:', 'Possibly related to XSLT transformation.');
        }
      }
    } else {
      console.error('Modeler is not initialized.');
    }
    
    // if (modeler) {
    //   modeler.saveXML({ format: true }).then(({ xml }) => {
    //     if (xml !== undefined) {
    //       const json = convert.xml2js(xml);
    //       const blobJson = new Blob([JSON.stringify(json)], { type: "application/json" });
    //       const linkJson = document.createElement("a");
    //       linkJson.href = window.URL.createObjectURL(blobJson);
    //       linkJson.download = "bpmnDiagram.json";
    //       document.body.appendChild(linkJson);
    //       linkJson.click();
    //       document.body.removeChild(linkJson);
    //     } else {
    //       console.error("XML is undefined.");
    //     }
    //   });
    // } else {
    //   console.error("Modeler is not initialized.");
    // }
  };

  // Files drag and drop 



  const handleDeleteDiagram = () => {
    if (modeler) {
      console.log('modeler..1')

      modeler.clear();
      // Optionally, perform any additional cleanup or actions
    } else {
      console.log('modeler..2')

      console.error('Modeler is not initialized.');
    }
  };
  

  function FormDataMaping(event) {

    const eventElement = event?.element;
    if (eventElement && (eventElement.type === 'bpmn:StartEvent' || eventElement.type === 'bpmn:UserTask')) {
      const datalist = document.createElement('datalist');
      datalist.id = 'FormOptions';
      const FormValue = [
        { name: 'form1', id: '1234' },
        { name: 'form2', id: '1264' },
        { name: 'form3', id: '1834' }
      ];
      
      // Add options to datalist
      FormValue.forEach((optionValue) => {
        const option = document.createElement('option');
        option.value = optionValue.name; // Set the value to the name property
        option.setAttribute('data-id', optionValue.id); // Set a data attribute for the id
        datalist.appendChild(option);
      });
      
      // Append datalist to the element with id 'js-properties-panel1'
      const panelElement = document.getElementById('js-properties-panel1');
      if (panelElement) {
        panelElement.appendChild(datalist);
      } else {
        console.error("Panel element with id 'js-properties-panel1' not found.");
      }
      
      const handleFormTypeChange = () => {
        const FormType = document.getElementById('bio-properties-panel-formType') as HTMLInputElement;
        const formRef = document.getElementById('bio-properties-panel-formRef') as HTMLInputElement;
        const formKey = document.getElementById('bio-properties-panel-formKey') as HTMLInputElement;
      
        if (!FormType || (!formRef && !formKey)) {
          console.log('FormType, formRef, or formKey element not found.');
          return;
        }
      
        const FormtypeValue = FormType.value;
        console.log('Option selected: ', FormtypeValue);
      
        if (FormtypeValue === "formRef" && formRef) {
          formRef.setAttribute("list", "FormOptions");
          formRef.addEventListener('change', function () {
            const selectedOption = FormValue.find(option => option.name === formRef.value);
            if (selectedOption) {
              console.log("input added - Name:", selectedOption.name, "ID:", selectedOption.id);
            }
          });
        } else if (FormtypeValue === "formKey" && formKey) {
          formKey.setAttribute("list", "FormOptions");
          formKey.addEventListener('change', function () {
            const selectedOption = FormValue.find(option => option.name === formKey.value);
            if (selectedOption) {
              console.log("input added - Name:", selectedOption.name, "ID:", selectedOption.id);
            }
          });
        } else {
          console.log("third");
        }
      };
      
      // const eventElement = event?.element;
      if (eventElement && (eventElement.type === 'bpmn:StartEvent' || eventElement.type === 'bpmn:UserTask')) {
        setTimeout(() => {
          const FormDiv = document.querySelector('[data-group-id="group-CamundaPlatform__Form"]') as HTMLElement;
          if (FormDiv) {
            console.log('present');
            const FormType = document.getElementById('bio-properties-panel-formType') as HTMLSelectElement;
            if (FormType) {
              FormType.addEventListener('change', handleFormTypeChange);
            } else {
              console.log('FormType element not found.');
            }
          } else {
            console.log('absent');
          }
        }, 100);
      }
    }
  }
  
  function FormTypeNameChange(event) {
    setTimeout(() => {
      const FormDiv = document.querySelector('[data-group-id="group-CamundaPlatform__Form"]') as HTMLElement;
      if (FormDiv) {
      
        FormDiv.addEventListener('click', function () {
          const FormType = document.getElementById('bio-properties-panel-formType') as HTMLSelectElement;
          if (FormType) {         
          const formTypeSelect = document.getElementById('bio-properties-panel-formType');

          const camundaFormsOption = formTypeSelect?.querySelector('option[value="formRef"]');

          if (camundaFormsOption) {
            camundaFormsOption.textContent = 'Workflow Forms';
          } else {
              console.error('Option with value "formRef" not found.');
          }
          } else {
            console.log('FormType element not found.');
          }
        })
      
    } else {
      console.log('formdiv absent');
      
    }
    }, 100);
    
  }


  const isButtonDisabled = addedCount === removedCount;

  return (
    <React.Fragment>
      <div className="App">
        <div className="workflow">
          <div
            id="container"
            ref={containerRef}
          ></div>
          <div className="properties-panel-parent" id="js-properties-panel1" style={{
            opacity: propertyPanelVisible ? 1 : 0,
          }}></div>
          <div className="buttonsdiv">
            <button onClick={handleDownloadXml} className="btn btn-dark " disabled={!modeler || isProcessEmpty || isButtonDisabled || errorCount !==0}>
              Save BPMN
            </button>
            <button onClick={handleDownloadjson} className="btn btn-dark ">
              Download BPMN in XML
            </button>
          </div>
        </div>
      </div>
      {/* <ToastContainer position="bottom-right" /> */}
    </React.Fragment>
  );
}
const mapStateToProps = (state: any) => ({
  // loading: state.kgReducer?.loading,
  // error: state.kgReducer?.errors,
  // kgListResponse: state.kgReducer?.kgListResponse,
  // kgGridData: state.kgReducer?.kgGridData,
  createWorkFlowResponse: state.workFlowReducer?.createWorkFlowResponse,
  createTaskWorkFlowResponse: state.workFlowReducer?.createTaskWorkFlowResponse,
  // deleteKGResponse: state.kgReducer?.deleteKGResponse,
  // totalElements: state.kgReducer?.totalElements,
  // subFunctionResponse: state.kgReducer?.subFunctionListDataResponse,
})

const mapDispatchToProps = (dispatch: any) => ({
  initWorkflowModuleAction: () => {
    dispatch(initWorkFlowModuleAction())
  },
  createWorkflowDetailAction: (data: any) => {
    dispatch(createWorkFlowDetailAction(data))
  },
  createtaskWorkflowDetailAction: (data: any) => {
    dispatch(createTaskWorkFlowDetailAction(data))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(App);
