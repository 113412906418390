import { AppActionTypes } from "./actiontype";

const initialState: any = {
    isLoading: false,
    loading: false,
    items: [],
    errors: {},
    createAppResponse: {},
}

const AppReducer: any = (state = initialState, action: any) => {
    const response = action.data;
    switch (action.type) {
        case AppActionTypes.INIT_APP_MODULE:
            return {
                ...state, loading: false, isLoading: false, items: [], createAppResponse: {}
            }
        case AppActionTypes.CREATE_APP_REQUEST:
            return { ...state, loading: true, createAppResponse: {} }
        case AppActionTypes.CREATE_APP_SUCCESS:
            return { ...state, loading: false, createAppResponse: response }
        case AppActionTypes.CREATE_APP_FAILURE:
            return { ...state, loading: false, errors: action.error }

        default: {
            return state;
        }
    }
}

export default AppReducer;