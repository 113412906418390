import { WorkFlowActionTypes } from "./actiontype"

export const initWorkFlowModuleAction = () => ({

    type: WorkFlowActionTypes.INIT_WORKFLOW_MODULE
})

export const createWorkFlowDetailAction = (data: any) => ({
    type: WorkFlowActionTypes.CREATE_WORKFLOW_REQUEST,
    data
})

export const createWorkFlowDetailSuccess = (data: any) => ({
    type: WorkFlowActionTypes.CREATE_WORKFLOW_SUCCESS,
    data
})

export const createWorkFlowDetailFailure = (error: any) => ({
    type: WorkFlowActionTypes.CREATE_WORKFLOW_FAILURE,
    error
})
export const createTaskWorkFlowDetailAction = (data: any) => ({
    type: WorkFlowActionTypes.CREATE_TASK_WORKFLOW_REQUEST,
    data
})

export const createTaskWorkFlowDetailSuccess = (data: any) => ({
    type: WorkFlowActionTypes.CREATE_TASK_WORKFLOW_SUCCESS,
    data
})

export const createTaskWorkFlowDetailFailure = (error: any) => ({
    type: WorkFlowActionTypes.CREATE_TASK_WORKFLOW_FAILURE,
    error
})

export const getWorkFlowDetailAction = (data: any) => ({
    type: WorkFlowActionTypes.GET_WORKFLOW_REQUEST,
    data
})

export const getWorkFlowDetailSuccess = (data: any) => ({
    type: WorkFlowActionTypes.GET_WORKFLOW_SUCCESS,
    data
})

export const getWorkFlowDetailFailure = (error: any) => ({
    type: WorkFlowActionTypes.GET_WORKFLOW_FAILURE,
    error
})