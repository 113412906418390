import { FormActionTypes } from "./actiontype"

export const initFormModuleAction = () => ({

    type: FormActionTypes.INIT_FORM_MODULE
})

export const createFormDetailAction = (data: any) => ({
    type: FormActionTypes.CREATE_FORM_REQUEST,
    data
})

export const createFormDetailSuccess = (data: any) => ({
    type: FormActionTypes.CREATE_FORM_SUCCESS,
    data
})

export const createFormDetailFailure = (error: any) => ({
    type: FormActionTypes.CREATE_FORM_FAILURE,
    error
})