import { takeLatest, put } from 'redux-saga/effects';
import { INCREMENT_MODULE1 } from '../actions/module1Actions';

function* handleIncrementModule1() {
  // You can add additional logic here
  yield put({ type: 'INCREMENT_MODULE1_SUCCESS' });
}

function* module1Saga() {
  yield takeLatest(INCREMENT_MODULE1, handleIncrementModule1);
}

export default module1Saga;