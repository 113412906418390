import { WorkFlowActionTypes } from "./actiontype";

const initialState: any = {
    isLoading: false,
    loading: false,
    items: [],
    errors: {},
    createWorkFlowResponse: {},
    createTaskWorkFlowResponse: {},
    getWorkFlowListResponse: {},
}

const workFlowReducer: any = (state = initialState, action: any) => {
    const response = action.data;
    switch (action.type) {
        case WorkFlowActionTypes.INIT_WORKFLOW_MODULE:
            return {
                ...state, loading: false, isLoading: false, items: [], createTaskWorkFlowResponse: {}, createWorkFlowResponse: {}
            }
        case WorkFlowActionTypes.CREATE_WORKFLOW_REQUEST:
            return { ...state, loading: true, createWorkFlowResponse: {} }
        case WorkFlowActionTypes.CREATE_WORKFLOW_SUCCESS:
            return { ...state, loading: false, createWorkFlowResponse: response }
        case WorkFlowActionTypes.CREATE_WORKFLOW_FAILURE:
            return { ...state, loading: false, errors: action.error }
        case WorkFlowActionTypes.CREATE_TASK_WORKFLOW_REQUEST:
            return { ...state, loading: true, createTaskWorkFlowResponse: {} }
        case WorkFlowActionTypes.CREATE_TASK_WORKFLOW_SUCCESS:
            return { ...state, loading: false, createTaskWorkFlowResponse: response }
        case WorkFlowActionTypes.CREATE_TASK_WORKFLOW_FAILURE:
            return { ...state, loading: false, errors: action.error }
        case WorkFlowActionTypes.GET_WORKFLOW_REQUEST:
            return { ...state, loading: true, getWorkFlowListResponse: {} }
        case WorkFlowActionTypes.GET_WORKFLOW_SUCCESS:
            return { ...state, loading: false, getWorkFlowListResponse: response }
        case WorkFlowActionTypes.GET_WORKFLOW_FAILURE:
            return { ...state, loading: false, errors: action.error }

        default: {
            return state;
        }
    }
}

export default workFlowReducer;